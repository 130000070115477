@import '../../../variables.scss';


.card {
    display: flex;
    background-color: white;
    margin-top: 1px;
    padding: 25px 25px 25px 22px;
    .right-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        div:first-of-type {
            margin-right: 10px;
        }
    }
    @media (max-width: 480px) {
        flex-direction: column;
    }
}

.label {
    color: $black;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    width: 50%;
}

.title {
    color: $pink;
    font-size: 19px;
    line-height: 55px;
    font-weight: 700;
}

.response {
    padding-left: 25px;
    padding-bottom: 25px;
    font-size: 14px;
    line-height: 17px;

}

.link {
    margin-bottom: 15px;
    margin-left: 20px;
    font-size: 13px;
    display: inline-block;
}


input {
    padding: 0!important;
}
