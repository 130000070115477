@import '../../../variables.scss';

.card {
    display: flex;
    background-color: white;
    margin-top: 1px;
    padding: 25px 25px 25px 22px;
}

.label {
    color: $black;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    width: 50%;
}
