@import '../../variables.scss';

.switch-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label {
    padding-right: 15px;
    cursor: pointer;
    font-size: 13px;
    line-height: 17px;
    color: $grey;
}

.is-active {
    color: $pink;
}

.switch-subtitle {
    font-size: 12px;
    line-height: 18px;
    color: $grey;
    padding-bottom: 20px;
    padding-top: 13px;
}

.card-body-wrapper {
    border-radius: 10px;
    padding: 90px 20px 30px 20px!important;
    background-color: $dusty-white;
    display: flex;
    flex-direction: column;
}

.card-wrapper {
    width: 318px;
    @media (max-width: 480px) {
        width: 265px;
    }
}

.pink-background {
    border-radius: 10px;
    padding: 25px 39px 0 39px;
    background: $pink 0% 0% no-repeat padding-box;
}

.card-title {
    text-align: left;
    font-size: 25px;
    line-height: 38px;
    color: $white;
    font-weight: 800;
    margin: 0;
}

.card-amount {
    text-align: left;
    font-size: 46px;
    line-height: 56px;
    color: $white;
    font-weight: 800;
}

.card-per {
    text-align: left;
    font-size: 14px;
    line-height: 21px;
    color: $white;
    font-weight: 500;
}

.card-mention {
    position: absolute;
    z-index: 2;
    left: 40px;
    text-align: left;
    font-size: 14px;
    line-height: 21px;
    color: $white;
    font-weight: 500;
    @media (max-width: 480px) {
        top: 117px;
    }
}

.curve {
    position: absolute;
    left: 0;
    top: 110px;
    width: 100%;
}

.svg-curve {
    position: absolute;
    left: 0;
    top: 54px;
    width: 100%;
}

.dollar {
    font-size: 30px;
    padding-right: 20px;
}

.amount {
    padding-right: 30px;
    @media (max-width: 480px) {
        padding-right: 10px;
    }
}

ul.list {
    list-style: none;
    font-size: 16px;
    color: $grey;
    line-height: 22px;
    padding-top: 25px;
    padding-left: 25px;
    font-family: Montserrat;
    font-weight: 300px;
    text-align: left;

    li {
        margin-bottom: 15px;
    }
}

ul.list li:before {
    content: '✓';
    color: $pink;
    font-weight: bold;
    padding-right: 10px;
}

.sign-in {
    color: $grey;
    font-size: 14px;
    line-height: 21px;
    padding: 20px 0;
}
