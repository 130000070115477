@import './variables.scss';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

// Override default variables before the import
$theme-colors: (
    'primary': $pink,
    'danger': $strava-red
);
$link-color: $grey;
$link-decoration: underline;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html {
    font-family: Montserrat;
    color: $black;
}

p, span, div {
    font-family: Montserrat;
}

body {
    margin: 0;
    padding: 0;
}

@mixin switch($res: 'sm') {
    $index: 1rem;
    $mainVal: 1rem;

    @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
    }

    .custom-control-label {
        padding-left: #{$index};
        padding-bottom: #{$mainVal};
        cursor: pointer;
        font-size: 13px;
        line-height: 17px;
        color: $grey;
    }

    .custom-control-label::before {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
        background-color: $pink;
    }

    .custom-control-label::after {
        width: calc(#{$mainVal} - 4px);
        height: calc(#{$mainVal} - 4px);
        border-radius: calc(#{$index} - (#{$mainVal} / 2));
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(calc(#{$mainVal} - 0.25rem));
    }
}

.custom-switch.custom-switch-sm {
    @include switch();
}

.custom-switch.custom-switch-md {
    @include switch('md');
}

.custom-control-input ~ .custom-control-label::after {
    background-color: $white;
}

p {
    margin: 0;
}

.card {
    border: none;
}

.custom-button {
    width: 187px!important;
    height: 50px!important;
    font-size: 14px;
    line-height: 21px;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pink {
    color: $pink;
}

.input-group {
    margin-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
}

.input-group > .form-control {
    border: none;
    color: $black;
    &:focus {
        border:none;
        box-shadow: none;
    }
}

.input-group-text {
    border: none;
    background: transparent;
    color: $grey;
}

.custom-input {
    padding-top: 12px;
}

.mr10 {
    margin-right: 10px!important;
}

.mb10 {
    margin-bottom: 10px;
}

.mb30{
    margin-bottom: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mt20 {
    margin-top: 20px!important;
}

.width100 {
    width: 100%!important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.error-message {
    color: $strava-red;
    display: flex;
    font-size: 12px;
    align-items: center;
    width: 100%;
}

.server-error-message {
    color: $strava-red;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 25px;
}

svg.slick-arrow {
    color: #22222233;
    width: 12px!important;
    &:hover {
        color: $grey;
    }
}

svg.slick-disabled {
    display: none !important;
}

.slick-slider {
    padding: 20px 0;
}

.slick-slide > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navbar-collapse {
    background-color: $pink;
    width: 100%!important;
    padding-left: 1rem;
}

.modal-dialog {
    height: 100%;
    min-width: 100%;
    margin: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-header {
    border: none;
    button {
        outline: none;
    }
}

.modal-content {
    width: 375px;
}

.modal-body {
    padding: 0 40px 40px 40px;
}

.modal-backdrop {
    background: #222222 0% 0% no-repeat padding-box;
    opacity: 0.8!important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
    color: $pink!important;
    border-left: 3px solid $pink;
    font-weight: 700;
}

.form-check-label {
    font-size: 16px;
    line-height: 25px;
    color: $grey;
}

.section-button {
    width: 215px;
    height: 50px;
    font-size: 14px;
    line-height: 21px;
}

.navbar {
    padding:  0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-brand {
    padding-top: 0;
    padding-left: 1rem;
    img {
        max-height: 38px;
    }
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-light .navbar-toggler {
    border: 0;
    outline: none!important;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.inline-button {
    height: 44px;
    margin-left: 20px;
}

.custom-date {
    .input-group {
        padding-bottom: 0px;
    }
}

button:disabled {
    cursor: default;
}

.CircularProgressbar  {
    width: 50%;
}

.circle-wrapper {
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
    margin-top: -30px;
    flex-direction: column;
    align-items: center;
}

.circle-title {
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
}

table {
    border: 1px solid #F0F0F0;
    thead {
        font-size: 13px;
        font-weight: 700;
    }
    tbody {
        font-size: 13px;
    }
}

@-moz-document url-prefix() {
    select.form-control {
        padding-right: 25px;
        background-image: url("data:image/svg+xml,\
        <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'\
                height='14px' viewBox='0 0 1200 1000' fill='rgb(51,51,51)'>\
            <path d='M1100 411l-198 -199l-353 353l-353 -353l-197 199l551 551z'/>\
        </svg>");
        background-repeat: no-repeat;
        background-position: calc(100% - 7px) 50%;
        -moz-appearance: none;
        appearance: none;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }
}

.strike {
    text-decoration: line-through;
}

.w-md-50 {
    @media (min-width: 768px) {
        width: 50% !important;
    }
}