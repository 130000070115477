@import '../../variables.scss';

.sign-in, .sign-in-link {
    color: $grey;
    font-size: 14px;
    line-height: 21px;
}

.sign-in-link {
    padding: 20px 0;
}

.privacy-policy {
    padding: 20px 0;
    font-size: 12px;
    line-height: 18px;
    color: $grey;
}

.create-account-form {
    width: 100%;
    margin-top: 40px;
}

.password-valid-check {
    color: #008000;
    font-weight: 700;
}