@import '../../variables.scss';

.card {
    cursor: pointer;
    border: 1px solid #F0F0F0!important;
    border-radius: 5px;
    min-width: 30%;
    width: 142px;
    height: 142px;
    display: flex!important;
    background-color: $white!important;
    box-shadow: none!important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 15px;
    margin-top: 20px;
    &:first-of-type {
        margin-right: 10px;
    }
    p{
        color: $grey;
    }
}

.card-selected {
    border: 1px solid $pink!important;
    background-color: $pink!important;
    p {
        color: $white;
    }
}
