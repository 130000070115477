@import '../../variables.scss';

.user-profile {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.banner {
    background-color: #363636;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 99px;
    color: white;
    @media (max-width: 480px) {
        flex-direction: column;
        padding: 40px;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.user {
    display: flex;
    @media (max-width: 480px) {
        margin-bottom: 10px;
    }
}

.title {
    text-align: left;
    font-size: 30px;
    line-height: 55px;
    font-weight: 900;
}

.subtitle {
    margin-top: 7px;
    text-align: left;
    font-size: 14px;
    line-height: 21px;
    font-weight: 200;
    opacity: 0.7;
}

.runs {
    display: flex;
    flex-wrap: wrap;
    div {
        margin-right: 30px;
    }
}

.time {
    font-size: 13px;
    line-height: 17px;
    font-weight: 600;
    display: inline-block;
    margin-right: 20px;
}

.distance {
    font-size: 11px;
    line-height: 17px;
    font-weight: 200;
    opacity: 0.7;
}

.wrapper {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    flex: 1 0 auto;
    padding: 40px 99px;
    min-height: calc(100vh - 265px);
    @media (max-width: 768px) {
        padding: 40px;
    }
}

.tabs {
    &:hover, &.selected {
        color: $pink;
        border-left: 1px solid $pink;
        font-weight: 900;
    }
}

.item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 0!important;
    padding-left: 23px!important;
    outline: none;
    color: $black;
    margin-bottom: 1px;
    font-size: 16px;
    line-height: 55px;
    border-radius: 0!important;
    &:hover, &.selected {
        color: $pink;
    }
}

form {
    background-color: white;
}

.section-card {
    display: flex;
    background-color: white;
    margin-top: 1px;
    padding: 0px 25px 0px 22px;
}

.section-title {
    color: $pink;
    font-size: 19px;
    line-height: 55px;
    font-weight: 700;
}

.six-test-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.previous {
    margin-right: 10px;
}
