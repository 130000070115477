.administration {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.administration-table-wrapper {
    padding: 20px;
    flex: 1 0 auto;
    overflow: auto;
}

.header {
    color: #fd1160;
    font-size: 19px;
    line-height: 55px;
    font-weight: 700;
}
