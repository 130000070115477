@import '../../variables.scss';

.training-plan {
    display: flex;
    flex: 1 0 auto;
    min-height: 100vh;
    flex-direction: column;
}

.zones-wrapper {
    background: #363636 0% 0% no-repeat padding-box;
    color: $white;
    padding: 20px 99px 10px 99px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 60px;
    .left, .right {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        div {
            margin-bottom: 10px;
        }
    }
    .left {
        div {
            margin-right: 17px;
        }
    }
    .right {
        justify-content: flex-end;
        margin: 0;
        p {
            text-align: right;
        }
        div {
            margin-left: 17px;
        }
    }
    @media (max-width: 480px) {
        padding: 20px 40px 10px 40px;
        flex-direction: column;
        justify-content: center;
        .left, .right {
            width: 100%;
            justify-content: center;
            flex-direction: column;
            p {
                text-align: center;
            }
            div {
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.race-details {
    background: #FD1160 0% 0% no-repeat padding-box;
    padding: 0 99px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 45px;

    .title {
        font-size: 16px;
        font-weight: 600;
        color: $white;
    }
    .custom {
        color: #22222280;
        text-transform: uppercase;
        margin-right: 15px;
    }
    .light {
        font-weight: 300;
    }
    @media (max-width: 480px) {
        flex-direction: column;
        padding: 0 40px;
    }
}

.carousel-title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    box-sizing: border-box;
    .week {
        color: $pink;
        font-size: 19px;
    }
    .month {
        color: $grey;
        font-size: 16px;
        font-weight: 300;
    }
    .total {
        color: $grey;
        font-size: 13px;
        text-transform: uppercase;
    }
    .light {
        font-weight: 300;
    }
    span {
        padding-right: 15px;
        box-sizing: border-box;
    }
}

.card-title {
    font-size: 13px;
    font-weight: 700;
}

.zone {
    font-size: 11px;
    font-weight: 700;
    color: $grey;
}

.time {
    font-size: 13px;
}

.card {
    width: 130px;
    height: 133px;
    margin: 0!important;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 11px 15px;
    outline: none;
    @media (min-width: 1800px) {
        width: 180px;
    }
    @media (min-width: 1440px) {
        width: 150px;
    }
    @media (max-width: 480px) {
        width: 120px;
    }
    @media (max-width: 330px) {
        width: 110px;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
    }


    .month {
        font-weight: 400;
        font-size: 12px;
        color: $grey;
        margin-right: 5px;
    }
    .day {
        font-weight: 700;
        font-size: 13px;
        color: $grey;
    }
    .flex {
        display: flex;
        justify-content: space-between;
    }
}

.container {
    padding: 50px 99px;
    flex: 1 0 auto;
    @media (max-width: 480px) {
        padding: 20px 40px;
    }
}

.progress {
    width: 200px;
    height: 8px!important;
    margin-bottom: 40px;
    width: 100%;
}

.training-card {
    width: 100%;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 14px 17px;
}

.content {
    width: 100%;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.time-wrapper {
    display: flex;
    align-items: center;
}
