@import '../../variables.scss';

.title {
    text-align: center;
    font-size: 25px;
    line-height: 25px;
    font-weight: 800;
    color: $black;
}

.subtitle {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: $black;
}
