@import '../../variables.scss';

.cards {
    width: 100%;
    margin-top: 20px;
    grid-template-columns: repeat(7, 1fr);
    display: grid;
}

.card {
    cursor: pointer;
    border: 1px solid #F0F0F0!important;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    background-color: $white!important;
    box-shadow: none!important;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none!important;
    color: $grey;

}

.card-selected {
    border: 1px solid $pink!important;
    background-color: $pink!important;
    color: $white;

}

ul.list {
    list-style: none;
    font-size: 16px;
    color: $grey;
    line-height: 22px;

    padding-left: 25px;
    font-family: Montserrat;
    font-weight: 300px;
    text-align: left;
}

ul.list li:before {
    content: '✓';
    color: $pink;
    font-weight: bold;
    padding-right: 10px;
}

.program {
    font-weight: 400;
    font-size: 11px;
    color: #aaaaaa;
    text-align: left;
    margin-bottom: 20px;
    margin-right: 10px;
}

.wrapper {
    position: relative;
    margin-bottom: 10px;
    display: grid;
}

.checkbox-wrapper {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    input {
        margin-top: 5px;
    }
}
