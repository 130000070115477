@import '../../variables.scss';

.cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.button {
    margin-top: 40px;
}
