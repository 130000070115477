$pink: #fd1160;
$black: #222222;
$grey: #aaaaaa;
$white: #ffffff;
$dusty-white: #f8f8f8;
$light-grey: #00000029;
$light-pink: #fd11604d;
$dusty-pink: #fd11601a;
$strava-red: #f45628;
$strava-button-red: #f05222;
$green: #00D88D;
