@import '../../variables.scss';

.payment-form, .payment-form-group, .payment-form-row {
    width: 100%!important;
    margin: 0!important;
}

.payment-form{
    padding-bottom: 20px;
}

.payment-card-wrapper {
    background-color: rgba(253, 17, 96, 0.1);
    background: #FD11601A 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 15px #0000001A;
    border: 2px solid #FD1160;
    border-top-width: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    padding: 10px 15px 10px 10px;
}

.payment-card-title {
    font-size: 13px;
    line-height: 17px;
    color: $pink;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
}

.payment-card-subtitle {
    font-size: 18px;
    line-height: 27px;
    color: $black;
    font-weight: 500;
}

.payment-card-text {
    font-size: 12px;
    line-height: 18px;
    // color: $light-pink;
    color: rgba(253, 17, 96, 0.4);
}

.payment-policy {
    font-size: 12px;
    line-height: 18px;
    color: $grey;
    padding-top: 15px;
    width: 100%;
}

.stripe-svg {
    width: 155px;
    height: 39px;
}

.input-border {
    border: 1px solid #F0F0F0!important;
    border-radius: 5px!important;
    margin-bottom: 10px!important;
    padding-right: 0!important;
    padding-left: 0!important;
}

.input-group {
    padding-left: 0!important;
}

.inline-group {
    display: flex;
    flex-direction: row;
}

.line, .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    margin-top: 5px;
    div {
        display: flex;
        flex-direction: row;
        p.grey {
            margin-right: 40px;
            color: $grey;
        }
        p {
            text-align: left;
            min-width: 30px;
        }
    }
}

.total {
    color: $pink;
    font-weight: bold;
    margin-top: 30px;
}

span.grey {
    color: $grey;
}
