@import '../variables.scss';

.middle-container {
    background: $white 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 400px;
    min-height: 100vh;
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .header-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
        &.center {
            justify-content: center;
        }
        a {
            font-size: 14px;
            line-height: 21px;
            color: $black;
            font-weight: 500;
        }
    }
    @media (max-width: 480px) {
        width: 300px;
    }
}

.background-image {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover !important;
    min-height: 100vh;
}

.logo {
    width: 180px;
    &.strava {
        padding-bottom: 10px;
    }
    img {
        width: 100%;
    }
        @media (max-width: 480px) {
        padding-bottom: 15px;
    }
}



.title {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    line-height: 32px;
    padding-bottom: 5px;
    width: 100%;
}

.subtitle {
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.header {
    background-color: $pink;
    height: 50px;
    justify-content: space-between;
    .logout {
        color: white!important;
        font-size: 12px;
        line-height: 18px;
        font-weight: 300;
        opacity: 0.7;
        text-align: left;
    }
}

.navigation {
    background-color: $pink;
    height: 50px;
    .navigation-links {
        width: 100%;
        justify-content: space-between;
        .wrapper {
            display: flex;
            flex-direction: row;
            @media (max-width: 990px) {
                flex-direction: column;
            }
            a {
                color: $white!important;
                font-size: 12px;
                line-height: 18px;
                font-weight: 300;
                opacity: 0.7;
                text-align: left;
                &:first-of-type {
                    margin-right: 10px;
                    margin-left: 10px;
                }
            }
            .user {
                display: flex;
                align-items: center;
                a {
                    margin: 0;
                    @media (max-width: 990px) {
                        padding-left: 5px;
                    }
                }

            }
        }
    }
}

.margin {
    margin: 30px 0;
    min-height: calc(100vh - 160px);
}

.center {
    justify-content: center!important;
}

.height {
    height: calc(100% - 110px);
    min-height: calc(100vh - 110px);
}

.progress {
    width: 200px;
    height: 4px!important;
    margin-bottom: 40px;
}

.input-border {
    border: 1px solid #F0F0F0!important;
    border-radius: 5px!important;
    margin-bottom: 10px!important;
    padding-right: 0!important;
    padding-left: 0!important;
}

.card {
    cursor: pointer;
    border: 1px solid #F0F0F0!important;
    border-radius: 5px;
    width: 142px;
    height: 142px;
    display: flex!important;
    background-color: $white!important;
    box-shadow: none!important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 15px;
    margin-top:20px;
    margin: 0 auto;
    &:first-of-type {
        margin-right: 10px;
    }
    p{
        color: $grey;
    }
}

.input-number {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        padding: 10px 0;
    }
}

.arrow-button, arrow-button:focus {
    padding-top: 0!important;
    background-color: $white!important;
    border: none!important;
    box-shadow: none!important;

}

.banner {
    color: $white;
    height: 300px;
    background-image: url('../assets/images/banner.png');
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    padding-left:  99px!important;
    border-radius: 0!important;
    margin: 0!important;
    div {
        max-width: 370px;
        h1 {
            font-size: 45px;
            line-height: 55px;
            text-transform: uppercase;
            font-weight: 800;
            @media (max-width: 480px) {
                font-size: 35px;
                line-height: 45px;
            }
        }
        p {
            font-size: 20px;
            line-height: 30px;
        }
    }
    @media (max-width: 480px) {
        padding-left:  40px!important;
    }
}

.footer {
    min-height: 50px;
    background-color: $black;
    display: flex;
    align-items: center;
    padding: 0 19px;
    color: $white;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    @media (max-width: 480px) {
        flex-direction: column;
    }
    .wrapper {
        width: 100%;
        opacity: 0.4;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 480px) {
            flex-direction: column;
        }
        img {
            max-height: 38px;
        }
        div {
            display: flex;
        }
        .social svg {
            margin-right: 10px;
        }
        p a {
            text-decoration: none;
            color: white;

        }
    }
}

.footer-links {
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 19px;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
}
